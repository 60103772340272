import React from "react";
import { ContentstackImg } from "@riotgames/wwpub-components"
import styled from "styled-components";
import { breakpoints, colors, zIndices } from "../styles/variables";

interface Props {
	videoOpen: Function;
	thumbnailImage: string;
	videoURL: string;
	textCaption: string;
	className?: string;
};

export default class VideoPreview extends React.Component<Props>{
	render() {
		const customClass = this.props.className || "";
		return (
			<VideoContainer className={ customClass } onClick={ () => this.props.videoOpen() }>
				<div className="videoContainerInner">
					<ContentstackImg style={thumbnailImage} image={{url: this.props.thumbnailImage}} />
					<video className="videoSmall" playsInline autoPlay loop muted>
						<source src={this.props.videoURL}></source>
					</video>
					<div className="cta">
						<svg viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M61 50L44.5 59.5L44.5 40Z"/>
							<circle cx="50" cy="50" r="45" />
						</svg>
						<div className="text-caption">{this.props.textCaption}</div>
					</div>
				</div>
			</VideoContainer>
		);
	}
}


export const VideoContainer = styled.div`
	position: relative;
	width: 50vh;
	max-width: 77.3%;
	border: 4px solid ${colors.primary};
	box-shadow: 8px 8px ${colors.primary};
	color: ${colors.primary};
	background: ${colors.primary};
	box-sizing: border-box;
	margin-bottom: 24px;
	cursor: pointer;
	transition: all 0.2s ease-out;
	.videoContainerInner {
		position: relative;
		padding-bottom: 56.25%;
		overflow: hidden;
	}
	.videoSmall {
		display: block;
		position: absolute;
		width: 100%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.cta {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: rgba(0, 0, 0, 0.5);
		svg {
			width: 100px;
			height: 100px;
			margin-bottom: 10px;
		}
		path {
			fill: ${colors.primary};
			transform-origin: 50% 50%;
			transition: transform 0.1s ease-out;
		}
		circle {
			fill: none;
			stroke: ${colors.primary};
			stroke-width: 2px;
			transform-origin: 50% 50%;
			transition: transform 0.1s ease-out;
		}
	}
	&:hover {
		box-shadow: 12px 12px ${colors.bright01};
		.text-caption {
			color: ${colors.white};
		}
		.cta path {
			fill: #fff;
			transform: scale(1.1);
		}
		.cta circle {
			stroke: #fff;
			transform: scale(0.9);
		}
	}
	@media (max-width: ${breakpoints.tablet}) {
		.cta svg {
			width: 78px;
			height: 78px;
		}
	}
	@media(max-width: ${breakpoints.mobileS}) {
		max-width: 300px;
		.cta svg {
			width: 48px;
			height: 48px;
		}
	}
`;

export const thumbnailImage = {
	position: "absolute",
	display: "block",
	width: "100%",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
} as const;
