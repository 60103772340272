import React from "react";
import styled from 'styled-components'
import { breakpoints, colors, eases } from "../../styles/variables";

const ButtonContainer = styled.div`
	display: inline-block;
	font-family: "ProximaNova-Black";
	font-size: 20px;
	letter-spacing: 0.01em;
	text-transform: uppercase;
	padding: 12px 32px;
	cursor: pointer;
	transition: all 0.2s ease-out, color 0.3s ease-out;
	position: relative;

	// Default is blue theme
	color: ${colors.white};
	border: 4px solid ${colors.primary};
	box-shadow: 4px 4px ${colors.primary};

	.background {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-size: 250% 100%;
		background-position: 0 0;
		transition: background-position 0.5s ${eases.inOut};
	}

	.text {
		position: relative;
	}
	&.theme-blue {
		color: ${colors.white};
		box-shadow: 4px 4px ${colors.primary};
		border: 4px solid ${colors.primary};
		.background {
			background-image: linear-gradient(30deg, ${colors.bright01} 50%, ${colors.white} 51%);
		}
	}
	&.theme-light {
		color: ${colors.bright01};
		box-shadow: 4px 4px ${colors.primary};
		border: 4px solid ${colors.primary};
		.background {
			background-image: linear-gradient(30deg, ${colors.white} 50%, ${colors.bright01} 51%);
		}
	}
	&.theme-dark {
		color: ${colors.white};
		box-shadow: 4px 4px ${colors.white};
		border: 4px solid ${colors.white};
		.background {
			background-image: linear-gradient(30deg, ${colors.smoke} 50%, ${colors.neutral.threeHundred} 51%);
		}
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
		box-shadow: none;
		.background {
			opacity: 0.5;
		}
	}

	&:hover {
		transform: translate(2px, 2px);
		.background {
			background-position: 100% 0;
		}
		&.theme-blue {
			color: ${colors.bright01};
			box-shadow: 2px 2px ${colors.bright01};
		}
		&.theme-light {
			color: ${colors.white};
			box-shadow: 2px 2px ${colors.smoke};
		}
		&.theme-dark {
			color: ${colors.smoke};
			box-shadow: 2px 2px ${colors.smoke};
		}
	}

`;

interface Props {
	colorTheme: string,
	disabled?: boolean,
	callback?: Function,
	className?: string
}

export default class ButtonText extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
	}

	btnClicked = (): void => {
		if (this.props.callback) {
			this.props.callback();
		}
	}

	render() {
		const colorClass = `theme-${this.props.colorTheme}`;
		const disabledClass = this.props.disabled ? "disabled" : "";
		const customClass = this.props.className ? this.props.className : "";

		return(
			<ButtonContainer onClick={ this.btnClicked } className={ `${colorClass} ${disabledClass} ${customClass}` }>
				<div className="background"></div>
				<span className="text">{this.props.children}</span>
			</ButtonContainer>
		);
	}
}
