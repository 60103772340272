/* eslint-disable max-len */

export const colors = {
  primary: "#00CBEA",
  black: "#111111",
  smoke: "#30353F",
  bright01: "#636AF2",
  bright02: "#7455FF",
  cream: "#F1EDE5",
  white: "#ffffff",
  lilac: "#FF7777",
  dark02: "#2C0C80",
  dark03: "#343984",
  dark04: "#2C2A59",
  accentBlue: "#C9FFFF",
  neutral01: "#2669BF",
  neutral02: "#4C51BD",

  neutral: {
    threeHundred: "#D4D4D4"
  }
}

export const breakpoints = {
  mobileS: "320px",
  mobile: "767px",
  tablet: "1200px",
  laptop: "1440px",
  desktop: "2560px"
}

export const eases = {
  inOut: "cubic-bezier(0.75, 0, 0.25, 1)",
  out: "cubic-bezier(0.0, 0.75, 0.25, 1)",
  in: "cubic-bezier(0.75, 0.0, 1, 0.25)",
}

export const zIndices = {
  hideBehind: -1,
  lightningClip: 1,
  championImg: 2,
  videoOverlay: 3,
}