import React from "react";
import styled from 'styled-components'
import { breakpoints, colors, eases } from "../../styles/variables";

const ButtonContainer = styled.div`
	display: inline-block;
	width: 50px;
	height: 50px;
	cursor: pointer;
	transition: all 0.2s ease-out, color 0.3s ease-out;
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	.background {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background-size: 300% 100%;
		background-position: 0 0;
		transition: background-position 0.5s ${eases.inOut};
	}

	svg {
		position: relative;
		width: 60%;
		height: 60%;
	}

	.pathOutline {
		fill: none;
		stroke-width: 6;
		transition: stroke 0.3s ease-out;
	}

	.pathFill {
		transition: fill 0.3s ease-out;
	}

	&.flipped {
		.background,
		svg {
			transform: scaleX(-1);
		}
	}

	&.theme-blue {
		color: ${colors.white};
		box-shadow: 4px 4px ${colors.primary};
		border: 4px solid ${colors.primary};
		.background {
			background-image: linear-gradient(45deg, ${colors.white} 49%, ${colors.bright01} 50%);
		}
		.pathOutline {
			stroke: ${colors.bright01};
		}
		.pathFill {
			fill: ${colors.bright01};
		}
	}
	&.theme-black {
		color: ${colors.white};
		box-shadow: 4px 4px ${colors.black};
		border: 4px solid ${colors.black};
		.background {
			background-image: linear-gradient(45deg, ${colors.white} 49%, ${colors.smoke} 50%);
		}
		.pathFill {
			fill: ${colors.smoke};
		}
	}

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
		box-shadow: none;
		.background {
			opacity: 0.5;
		}
		svg {
			opacity: 0.3;
		}
	}

	&:hover {
		transform: translate(2px, 2px);
		.background {
			background-position: 100% 0;
		}
		&.theme-blue {
			color: ${colors.bright01};
			box-shadow: 2px 2px ${colors.bright01};
			.pathOutline {
				stroke: ${colors.white};
				transition: stroke 0.3s ease-out;
			}
			.pathFill {
				fill: ${colors.white};
			}
		}
		&.theme-black {
			color: ${colors.black};
			box-shadow: 2px 2px ${colors.black};
			.pathFill {
				fill: ${colors.white};
			}
		}
	}
`;

const SVG_DATA = {
	close: "M3,3 L37,37 M3,37 L37,3",
	arrow: "M6,20 L28,0 L28,40 Z",
};

type ButtonType = "close" | "arrow";
type ButtonTheme = "blue" | "black";

interface Props {
	colorTheme: ButtonTheme,
	buttonType: ButtonType,
	callback?: Function,
	flipped?: boolean,
	disabled?: boolean,
	className?: string
}

export default class ButtonUI extends React.Component<Props> {
	constructor(props: Props) {
		super(props);
	}

	btnClicked = (): void => {
		if (this.props.callback) {
			this.props.callback();
		}
	}

	render() {
		const disabledClass = this.props.disabled ? "disabled" : "";
		const colorClass = `theme-${this.props.colorTheme}`;
		const flippedClass = this.props.flipped ? "flipped" : "";
		const customClass = this.props.className ? this.props.className : "";

		const path = SVG_DATA[this.props.buttonType];
		const pathClass = this.props.buttonType === "close" ? "pathOutline" : "pathFill";

		return(
			<ButtonContainer onClick={ this.btnClicked } className={ `${colorClass} ${flippedClass} ${disabledClass} ${customClass}` }>
				<div className="background"></div>
				<svg viewBox="0 0 40 40">
					<path className={ pathClass } d={ path }></path>
				</svg>
			</ButtonContainer>
		);
	}
}
